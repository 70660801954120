
import AOS from "aos";
import "aos/dist/aos.css";
import { mapGetters } from "vuex";

export default {
  mounted() {
    AOS.init({
      once: true
    });
    const sliderIndexSwiper = new swiper.default(".slider-index .swiper", {
      modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: true,
      slidesPerView: 1,
      // autoHeight: true,
      watchSlidesProgress: true,
      scrollbar: false,
      speed: 1800,
      autoplay: {
        delay: 6000
      },
      on: {
        sliderMove: this.sliderMove,
        slideChangeTransitionStart: this.slideChangeTransitionStart
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: "true",
        type: "bullets",
        renderBullet: function (index, className) {
          return (
            '<span class="' +
            className +
            '">' +
            '<span class="swiper-pagination-bar"></span>' +
            '<span class="swiper-pagination-progress"></span>' +
            "</span>"
          );
        }
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  methods: {
    sliderMove() {
      document
        .querySelectorAll(".swiper-slide-next .slider-index__media")
        .forEach(val => {
          // val.style.display = 'none'
          // val.classList.remove("aos-init", "aos-animate");
          // AOS.init({
          // 	once: true
          // })
          // val.style.display = 'block'
        });
      document
        .querySelectorAll(".swiper-slide-next .slider-index__circle")
        .forEach(val => {
          val.style.display = "none";
          val.classList.remove("aos-init", "aos-animate");
          val.style.display = "block";
          val.classList.add("aos-init", "aos-animate");
        });
      // AOS.init()
    },
    slideChangeTransitionStart() {
      // document.querySelectorAll('.swiper-slide-active .slider-index__media').forEach((val) => {
      // 	val.style.display = 'none'
      // 	val.classList.remove("aos-init");
      // 	val.style.display = 'block'
      // })
      // document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
      // 	val.style.display = 'none'
      // 	val.classList.remove("aos-init");
      // 	val.style.display = 'block'
      // })
      // AOS.refreshHard()
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    }),
    slides() {
      return [
        {
          id: 10,
          heading: "Автокредит",
          content: `Скидка до 300 000 ₽, одобрение по паспорту и В/У`,
          link: "/credit",
          image: require("~/assets/img/slider-main/1.webp")
        },
        {
          id: 6,
          heading: `Trade-In`,
          content: `Выгода до 300 000 ₽, рыночная цена, оформление 30 мин`,
          link: "/exchange",
          image: require("~/assets/img/slider-main/2.webp")
        },
        {
          id: 8,
          heading: "Рассрочка",
          content: `Первоначальный взнос от 0%`,
          link: "/installments",
          image: require("~/assets/img/slider-main/3.webp")
        },
        {
          id: 1,
          heading: "Выкуп",
          content: `Мгновенная выплата в день обращения`,
          link: "/buyout",
          image: require("~/assets/img/slider-main/4.webp")
        }
      ];
    }
  }
};
